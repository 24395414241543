.sidenav-container {

  //.fa {
  //  @include transition-all;
  //  &:hover {
  //    cursor: pointer;
  //    color: $gray-100;
  //  }
  //}


.mat-sidenav {
  .dropdown-item:before  {
    font-family: 'FontWebshop';
    font-style: normal;
    display: inline-block;
    font-weight: normal;
    font-size: .65rem;
    content: "\f054";
    padding-right: .25em;
    color: $indigo;
    //-webkit-font-smoothing: antialiased;
    //-moz-osx-font-smoothing: grayscale;
  }

  .lead {
    text-transform: uppercase;
    font-family: $headings-font-family;
  }

}

  .mat-drawer-inner-container {
    background: $white;

  .mat-accordion {

    .mat-expansion-panel:not([class*='mat-elevation-z']) {
      border-radius: 0;
    }

    .mat-expansion-panel-spacing {
      margin: 0;
    }

    .mat-expansion-panel-body {
      padding: 0;
      background: lighten($cyan, 20%);
    }

    .mat-expansion-panel:not(:last-child){
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

  .mat-expansion-panel {

.mat-expansion-indicator:after {
  color: $cyan;
}

    .mat-expansion-panel-header {
      // background-image: linear-gradient(white, lighten($warning, 20%));
      // background-image: linear-gradient(to bottom, lighten($teal, 15%), lighten($teal, 20%),);
      //  background: linear-gradient(135deg, $white 0%, $gray-200 60%, $gray-200 100% );
      background: linear-gradient( 135deg, darken($indigo, 8%)  0%, darken($indigo, 2%) 60%, $indigo 100% );
      @include transition-all;
      border-radius: 0;
      height: 2.5em !important;

      &:hover {
        background: linear-gradient( 135deg, $indigo 0%, lighten($indigo, 2%) 60%, lighten($indigo, 5%) 100% );
      }

      .mat-expansion-panel-header-title {
        text-transform: uppercase;
        font-size: .95rem;
        color: lighten($blue, 25%);
        font-weight: 400;
      }
    }
  }
  }
}
}

