.sidecart-container {
  background: $white;
  padding: $grid-gutter-width $grid-gutter-width / 2 $grid-gutter-width * 8 $grid-gutter-width / 2;
  // max-width: 800px;


  .product-image { float: left; width: 20%;}
  .product-details { float: left; width: 48%}
  .product-quantity { float: left; text-align: center; width: 20%;}
  .product-removal {
    background-color: $white;
    border: 1px solid $primary;
    border-radius: 10px;
    float: right;
    margin-right: -6px;
    margin-top: -11px !important;
    padding: 0 10px;
    width: 7%;

    &:hover {
      border: 1px solid $secondary;
      cursor: pointer;
      i {
        color: $secondary;
      }
    }

    i {
      margin-left: -.29em;
      color: $primary;
    }
  }

  @include media-breakpoint-up(sm) {
    max-width: 500px;
  }


  @include media-breakpoint-up(md) {
    .product-image { width: 14%;}
    .product-details { float: left; width: 60%}
    .product-quantity { width: 22%;}
    .product-removal {
      margin-top: .25em;
      width: 3%
    }
  }

  /* Product entries */
  .product {
    border: 1px solid $gray-200;
    margin-bottom: 10px;
    padding-bottom: 5px;
    padding-top: 5px;

    //.btn-quantity {
    //  width: 92px;
    //  // height: 30px;
    //}

    .price-input {
      width: 40px;
      float: left;
      margin-left: 0;
    }

    .product-image {
      height: 40px;
      padding: $spacer $spacer * 3 $spacer $spacer;
      width: 40px;
      @include flexbox();
      @include align-items(center);
      @include justify-content(center);

      @include media-breakpoint-up(md) {
        width: 60px;
        height: 60px;
      }


      img {
        max-height: 100%;
        max-width: 100%;
      }
    }

    .product-details {
      .product-title {
        font-family: $headings-font-family;
        font-weight: 700;
        margin-right: 20px;
        text-transform: uppercase;
      }
      .product-description {
        margin: 5px 20px 5px 0;
        line-height: 1.4em;
      }

      .form-text{
        background-color: $orange-200;
        color: $black;
        margin-left: -50px;
        //white-space: nowrap;
       // background-image: linear-gradient(to right, $orange-300, $orange-300, $orange-300, $white);
        display: block;
        padding: .25em .25em .15em;
      }

      .fa {
        margin-right: .25em
      }

    }



    //.product-quantity {
    //  input {
    //    width: 60px;
    //    color: black;
    //
    //  }
    //}

    //.remove-product {
    //  border: 0;
    //  padding: 4px 8px;
    //  background-color: $primary;
    //  color: #fff;
    //  font-family: $headings-font-family;
    //  font-size: 12px;
    //  border-radius: 3px;
    //}
    //
    //.remove-product:hover {
    //  background-color: $danger;
    //}
  }

  .product-title {
    display: block;
    font-size: 1rem;
    // text-transform: uppercase;
  }

  h3 {
    color: $black;
    font-family: $headings-font-family;
    font-weight: 700;
    padding-bottom: 0;
    text-transform: uppercase;
  }

  //.product-details {
  //  max-width: 500px;
  //}

  //.prod-image-container {
  //  height: 60px;
  //  padding: $spacer;
  //  width: 60px;
  //  @include flexbox();
  //  @include align-items(center);
  //  @include justify-content(center);
  //
  //  img {
  //    max-height: 100%;
  //    max-width: 100%;
  //  }
  //}

  .fa-trash {
    margin-left: 2em;
    margin-top: .5em;
    &:hover {
      color: $danger;
    }
  }

  .text-danger {
    font-size: 1.2rem;
  }

  .sidecart-bottom {
    background-color: $white;
    padding: $grid-gutter-width / 2 $grid-gutter-width / 2 $grid-gutter-width $grid-gutter-width / 2;
    position: absolute;

    hr {
      margin-bottom: $grid-gutter-width;
    }

    .btn {
      margin-top: $grid-gutter-width;
    }

  }


}

.badge-amount {
  margin-left: -0.85em;
  margin-top: 0.5em;
  position: absolute;
}



