.main-carousel {
  background-color: $gray-100;
  margin-top: $grid-gutter-width;
  @include flexbox();
  @include align-items(center);
  @include justify-content(center);

  h1, a {
    color: $white;
    margin-bottom: 0;
    text-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
    text-transform: uppercase;
  }

  a {
    text-decoration: none;
  }

  h1 {
    font-size: 1.5rem;
    @include media-breakpoint-up(lg) {
      font-size: 2.5rem;
    }
  }

  p {
    font-family: $headings-font-family;
    font-size: 1rem;
    @include media-breakpoint-up(lg) {
      font-size: 1.4rem;
    }
    font-weight: 100;
    text-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
  }

  .carousel-caption {
    //background-color: rgba(0,0,0,.6);
    background: linear-gradient(90deg, transparent, rgba(0,0,0,.6), transparent 90.71%);
  }

}

blockquote {
  background: $red-100;
  border-left: 5px solid $red-200;
  font-weight: 500;
  margin: $grid-gutter-width 0;
  padding: .75em;

  h2 {
    padding: $spacer;
    a {
      color: $primary;
    }
  }

  p {
    margin-bottom: 0;
  }
  // quotes: "\201C""\201D""\2018""\2019";
}

.scroll-block {
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  //scrollbar-color: $gray-400 $primary;
  //scrollbar-width: thin;
  //
  //::-webkit-scrollbar {
  //  height: 12px;
  //  width: 12px;
  //}
  //::-webkit-scrollbar-track {
  //  background: $gray-400;
  //}
  //::-webkit-scrollbar-thumb {
  //  background: darken($primary, 40%);
  //}
}


.parallax-block {
  background-attachment: fixed;
  background-image: url("/assets/images/ads/parallax-banner.jpg");
  background-position-x: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 260px;

  @include flexbox();
  @include align-items(center);
  @include justify-content(center);
  @include flex-direction(column);

  h1 {
    display: block;
  }
}



.main {
 // background: linear-gradient(10deg, lighten($blue, 20%), lighten($teal, 20%), lighten($blue, 20%) 90.71%);
//  background-attachment: fixed;
}

//.product-list, .product-detail {
//  @include media-breakpoint-up(xl) {ul
//  //  padding-top: $grid-gutter-width;
//  }
//}

.subcats-container {
  .btn {
    float: left;
    margin-right: $spacer;
  }
}
.small-label {
  font-size: .75rem;
  text-transform: uppercase;
  //margin-top: -.75em;
}

.product-list, .product-detail, .sub-categories {
  padding-left: 1em;
  padding-right: 1em;

  h1 {
    font-family: $headings-font-family;
    padding-bottom: 0;
    padding-top: .25em;
    text-align: center;
    text-transform: uppercase;
    font-size: 1.1rem;

    @include media-breakpoint-up(sm) {
      font-size: 1.2rem;
    }

    @include media-breakpoint-up(md) {
      font-size: 1.4rem;
    }

    @include media-breakpoint-up(lg) {
      font-size: 1.6rem;
    }

    @include media-breakpoint-up(xxxl) {
      font-size: 1.8rem;
      padding-top: $grid-gutter-width;
    }

  }

  hr {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(82,138,214,.5), rgba(0, 0, 0, 0));
    border: 0;
    height: 1px;
    margin-bottom: $grid-gutter-width;
  }

  @include media-breakpoint-up(sm) {
    padding-left: $spacer;
    padding-right: $spacer;
  }

  @include media-breakpoint-up(md) {
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
  }

  @include media-breakpoint-up(xl) {
    padding-left: $grid-gutter-width * 2;
    padding-right: $grid-gutter-width * 2;
  }

  @include media-breakpoint-up(xxxl) {
    padding-left: $grid-gutter-width * 3.5;
    padding-right: $grid-gutter-width * 3.5;
  }
}

.categories {

  padding-top: $grid-gutter-width;

  @include media-breakpoint-up(xl) {
    padding-left: $grid-gutter-width * 3;
    padding-right: $grid-gutter-width * 3;
  }

  @include media-breakpoint-up(xxxl) {
    padding-left: $grid-gutter-width * 4.5;
    padding-right: $grid-gutter-width * 4.5;
  }
}


.data-form, .check-out-container  {

  small {
    animation: 1s messageAnima;
    animation-fill-mode: forwards;
    //background-color: lighten($orange, 10%);
    background-color: $danger;
    border: 1px solid $white;
    border-radius: 2em;
    color: $white;
    font-family: $headings-font-family;
    font-size: .9rem;
    font-weight: 700;
    margin-right: .5em;
    margin-top: -.65em;
    padding-left: .5em;
    padding-right: .5em;
    position: absolute;

    right: 0;
    top: 0;
    visibility: hidden;
  }

  .message {
    animation: 1s messageAnima;
    animation-fill-mode: forwards;
    visibility: hidden;
  }


  @keyframes messageAnima {
    0% {
      opacity: 0;
      visibility: hidden;
    }

    100% {
      opacity: 1;
      visibility: visible;
    }
  }


  .delayed-message {
    animation: 2s delayedAnima;
    animation-fill-mode: forwards;
    visibility: hidden;
  }

  @keyframes delayedAnima {
    0% {
      opacity: 0;
      visibility: hidden;
    }

    80% {
      opacity: 0;
      visibility: hidden;
    }

    100% {
      opacity: 1;
      visibility: visible;
    }
  }

  [class^="col-"], [class*=" col-"] {
    padding-bottom: $spacer;
  }

  .form-floating {
    margin-bottom: .5em;
    position: relative;
    input.ng-valid {
      border: 1px solid $green;
      background-color: $green-100 !important;
    }
    input.ng-invalid {
      border: 1px solid $red;
      background-color: $red-100 !important;
    }
  }
}

.check-out-container, .page-container {

  //input[type=password].ng-valid, input[type=email].ng-valid {
  //  border: 1px solid $green;
  //  background-color: $green-100;
  //}


  @include media-breakpoint-up(lg) {
    min-height: calc(100vh - 528px);
  }

  @include media-breakpoint-up(xl) {
    padding-left: 5%;
    padding-right: 5%;
  }

  @include media-breakpoint-up(xxl) {
    padding-left: 6%;
    padding-right: 6%;
  }

  @include media-breakpoint-up(xxxl) {
    padding-left: 12%;
    padding-right: 12%;
  }
}


.headline-block {
  margin-bottom: $spacer * 2;
  margin-top: $spacer * 2;
  padding-bottom: $spacer;
  padding-top: $spacer;



  @include media-breakpoint-up(lg) {
    margin-bottom: $grid-gutter-width;
    margin-top: $grid-gutter-width;
    padding-bottom: 1em;
    padding-top: 1em;
  }


  text-align: center;
  text-transform: uppercase;

  h2 {
    margin: 0;
  }
}

.female-bg {
  background-color: $pink;
}

.male-bg {
  background-color: $blue;
}

.kid-bg {
  background-color: $yellow-100;
}


.ad-top {
  background-color: $danger;
 // text-align: center;
  color: $white;


  [class*="col-"] {
    @include flexbox();
    @include align-items(center);
    //@include justify-content(center);
    height: 40px;

    p {
      margin-bottom: 0;
    }
  }

.fa {
  @include transition-all;
  &:hover {
    color: $warning;
    cursor: pointer;
  }

  &:active {
    color: $white;
  }
}

}

.ad-head {
  background-color: $white;
  text-align: center;
  color: $gray-700;
  height: 200px;
}

//.footer-wawe-container {
//  overflow: hidden;
//
//
//.footer-wave {
//  fill: $blue;
//  width: 130%;
//  height: 100px;
//
//  transform: rotate(180deg);
//
//  @include media-breakpoint-up(lg) {
//    height: 200px;
//  }
//
//  @include media-breakpoint-up(xxl) {
//    height: 300px;
//  }
//}
//}

// SHAPE DIVIDERS

.shape-divider-secondary {
  .shape-fill {
    fill: $secondary;
  }
}

.shape-divider-primary {
  .shape-fill {
    fill: $gray-800;
  }
}

.shape-divider-pink {
  .shape-fill {
    fill: $pink;
  }
}


// FOOTER
.shape-divider-top {
  left: 0;
  line-height: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;

  svg {
    display: block;
    height: 19px;
    position: relative;
    width: calc(100% + 1.3px);
  }
}

.shape-divider-bottom {
  left: 0;
  line-height: 0;
 // bottom: 320px;
  margin-top: -28px;
  overflow: hidden;
  position: absolute;
  width: 100%;
}

.shape-divider-bottom svg {
  position: relative;
  display: block;
  width: calc(128% + 1.3px);
  height: 28px;
}


.footer {
  background-color: $gray-800;
  color: $white;
  margin-top: $grid-gutter-width * 2;
  //border-bottom: 20px solid $orange;
  // padding-top: $grid-gutter-width * 2;
  padding-bottom: 0;

  .container {
    padding-top: $grid-gutter-width * 2;
  }

  .social-headline {
    padding: .5em 1em;
    background-color: $gray-600;
    border-top-left-radius: $border-radius-sm;
    border-top-right-radius: $border-radius-sm;
  }

  .fa {
    font-size: 2rem;
    margin-right: .15em;
  }

  h5 {
    border-bottom: 1px dashed $secondary;
    color: $white;
    font-weight: 400;
    margin-bottom: 1em;
    text-transform: uppercase;
  }

  li span {
    &:hover {
      cursor: pointer;
    }
  }

  a, li span {
    color: $white;
    text-decoration: none;
    @include transition-all();

    &:hover {
      color: $secondary;
    }
  }

  ul {
    list-style-type: none;
    padding: 0;

    li {
      font-size: 1.2rem;
      padding: 0;

      a, span {
        &:before  {
          color: $white;
          content: "\f054";
          display: inline-block;
          font-family: 'FontWebshop';
          font-size: .85rem;
          font-style: normal;
          font-weight: normal;
          padding-right: .25em;
          //-webkit-font-smoothing: antialiased;
          //-moz-osx-font-smoothing: grayscale;
        }
      }
    }
  }

  .payment-cc {
    text-align: center;
    width: 100%;
    .fa {
      color: $white;
      font-size: 3rem;
      margin:  0 .2em;
    }
  }

  //text-align: center;

  //@include flexbox();
  //@include align-items(center);
  //@include justify-content(center);

  p {
    margin-bottom: 0;
  }

  //svg {
  //  display: inline-block;
  //  left: 0;
  //  position: absolute;
  //  top: 0;
  //  z-index: -1;
  //}
  //.wave-container {
  // // bottom: 0;
  // // // display: inline-block;
  // // left: 0;
  // ////  margin-top: -460px;
  // // overflow: hidden;
  // // padding-bottom: 100%;
  // // position: absolute;
  // // vertical-align: middle;
  // // width: 100%;
  //}


}

.footer-bottom {
  background-color: $gray-600;
  color: $white;
  text-align: center;
  padding-top:  $grid-gutter-width;
  padding-bottom: $grid-gutter-width;
  .fa {
    margin-right: .15em;
    margin-left: .15em;
    font-size: 3rem;
    color: $white;
  }
}

.categories, .sub-categories{

  [class*=col-]{
    margin-bottom: $grid-gutter-width / 2;
  }

  @include media-breakpoint-up(md) {
    margin-bottom: $grid-gutter-width;
  }

  .hidden {
    height: 0;
    overflow: hidden;
  }

  //.cat-cards {
  //  @include media-breakpoint-up(xl) {
  //  //  padding-right: $grid-gutter-width * 4;
  //    padding-left: $grid-gutter-width * 8;
  //  }
  //}

  // min-height: 1000px;

  //h1 {
  //  text-align: center;
  //  text-transform: uppercase;
  //  padding-top: $grid-gutter-width;
  //}

  .card {
    padding: 0;
    border: 0;
    overflow: hidden;
    @include box-shadow(0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12));
    @include transition-all;


    &:hover {
      @include box-shadow(0 4px 4px rgba(10, 16, 20, .24), 0 0 8px rgba(10, 16, 20, .12));
      cursor: pointer;
      @include transition-all;
    }

  .card-img-overlay {
    padding: 0;

    //@include flexbox();
    //@include align-items(flex-end);
    //@include justify-content(center);

  .card-title {
    font-family: $headings-font-family;
    color: $white;
    font-size: 1.4rem;
    line-height: 1.2;

    @include media-breakpoint-up(sm) {
      font-size: 1.9rem;
    }

    @include media-breakpoint-up(md) {
      font-size: 2rem;
    }

    @include media-breakpoint-up(xl) {
      font-size: 1.9rem;
    }

    @include media-breakpoint-up(xxl) {
      font-size: 1.8rem;
    }


    @include media-breakpoint-up(xxxl) {
      font-size: 2rem;
    }

    //
    //@include media-breakpoint-up(xxl) {
    //  font-size: 2rem;
    //}


    width: 100%;
    text-transform: uppercase;
    padding-top: $spacer;
   // padding-bottom: $spacer;
    margin: 0;
    padding-left: $grid-gutter-width / 2;
    @include transition-all;
    &:hover {
      color: $cyan;
      cursor: pointer;
    }
  }

    .card-text {

      ul {
        margin-bottom: 0;
        padding-bottom: $spacer;
        li {
          padding: .2em 0 .2em $grid-gutter-width / 2;
        //  font-family: $headings-font-family;
          font-weight: 400 !important;
          color: $white;
          font-size: 1rem;
          text-decoration: none;
          @include transition-all;

          @include media-breakpoint-up(xl) {
            padding: .24em 0 .24em $grid-gutter-width / 2;
          }

          @include media-breakpoint-up(xxl) {
            padding: .2em 0 .2em $grid-gutter-width / 2;
          }

          @include media-breakpoint-up(xxxl) {
            padding: .3em 0 .3em $grid-gutter-width / 2;
          }


          &:hover {
            background-color: $primary;
            color: $info;
            cursor: pointer;
          }
        }
      }



    }

    .card-title {
    //  background: rgba(73,66,60, 0.8);
      font-family: $headings-font-family;
      text-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
      background: linear-gradient(8deg, rgba(142,138,214,.8), rgba(73,66,60, 0.8) 50.71%);
      padding-left: .1em;


      @include media-breakpoint-up(md) {
        padding-left: .25em;
      }

      @include media-breakpoint-up(xxxl) {
        padding-left: .3em;
      }
    }

    .card-text {
      background: rgba(73,66,60, 0.8);
    }
    }
  }
  // CARD END



  .card-sub {
    border-top: 1px solid $gray-100;
    border-left: 1px solid $gray-100;
    border-right: 1px solid $gray-100;

    img {
      @include transition-all;
    }

    &:hover {
      img {
        transform: scale(1.05);
      }
    }



    .card-img-overlay {
      @include transition-all;
      //border: 10px solid;
      //border-image-slice: 1;
      //border-width: 5px;
      //border-image-source: linear-gradient(to left, $gray-300, $gray-100);
      //background: rgba(0,0,0, 0.5);
     // background: linear-gradient(135deg, $blue 0%, $cyan 30%, $blue 50%, $cyan 70%, $blue 100% );


      //background: linear-gradient(8deg, rgba(26,0,78,.8), rgba(255,0,0,0) 84.71%),
      //linear-gradient(-8deg, rgba(134,51,52,.8), rgba(0,255,0,.04) 34.71%);


      background: linear-gradient(8deg, rgba(82,138,214,.8), rgba(255,0,0,0) 50.71%),
                  linear-gradient(-8deg, rgba(203,118,119,.8), rgba(0,255,0,.04) 30.71%);

      //background: linear-gradient(8deg, rgba(82,138,214,.8), rgba(255,0,0,0) 50.71%),
      //linear-gradient(-8deg, rgba(203,118,119,.8), rgba(0,255,0,.1) 40.71%);

      &:hover {

        //background: linear-gradient(8deg, rgba(82,138,214,.8), rgba(255,0,0,0) 50.71%),
        //            linear-gradient(-8deg, rgba(203,118,119,.8), rgba(0,255,0,.04) 30.71%);
        .card-title, .card-text {
          color: $white;

         // text-shadow: none;
            transform: none;
        }
      }

      .card-title {
        text-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
        font-size: 1.3rem;
      }


    .card-title, .card-text {
      background: none;
      // background: linear-gradient(8deg, rgba(142,138,214,.2), rgba(73,66,60, 0.2) 50.71%)
    }
    }

  }
}

// SUB CATEGORIES LIST PAGE
.sub-categories {
  //@include media-breakpoint-up(xl) {
  //  padding-left: $grid-gutter-width * 3;
  //  padding-right: $grid-gutter-width * 3;
  //}
  //.card-img-overlay {
  //  .card-title {
  //    font-size: 1.3rem;
  //  }
  //}

}


.left-sidenav-container {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}


// PRODUCT LIST ///////////////////////////////////////////////////////////
.product-list {
  // background-color: $white;
  .card {
    .card-header {
      fill: $white;
      height: 100%;
      //@include flexbox;
      padding: 0;
      //@include align-items(center);
      //@include justify-content(center);
    }
  }

  //background: linear-gradient(8deg, rgba(82,138,214,.8), rgba(255,0,0,0) 50.71%),
  //linear-gradient(-8deg, rgba(203,118,119,.8), rgba(0,255,0,.04) 30.71%);
//.btn-group {
//  border: 3px solid $white;
//}
.btn-nav {
.btn-sm {
  margin-bottom: 0;
  color: $white;
  font-weight: 500;
  font-size: .9rem;
  @include transition-all;
  //padding-top: .45em;
  //padding-bottom: .45em;
}

  .dropdown-toggle {
    text-transform: uppercase;
  }

  .mat-form-field-wrapper {
    margin-top: .35em;

    @include media-breakpoint-up(xxl) {
      margin-top: .35em;
    }

    padding-bottom: 0;
    background: rgba(255,255,255, .3);
  }

  .mat-form-field-infix {
    padding: 0.586em 0;
    border-top: 2px solid transparent;
  }

  .mat-select-arrow {
    margin: 8px 0 0 4px;
  }


  .mat-form-field-label {
    margin-top: .48em;
    color: $gray-300;
    text-transform: uppercase;
    font-size: .9rem;
   // font-family: Dosis, sans-serif;
  }

}
  //.fa-angle-down {
  //  padding-top: 1em;
  //}

  .flttxt {
    line-height: 2.5;
    margin-right: $spacer;
    color: $gray-400;
    text-transform: uppercase;
    font-size: .85rem;
  }


    .card {
    width: 100%;
    margin-bottom: $spacer * 4;
    border: 0;
    background: linear-gradient(10deg, rgba(82,138,214,.1), rgba(255,255,255, 1) 40.71%),
                linear-gradient(-8deg, rgba(203,118,119,.05), rgba(0,255,0,.05) 30.71%);

    // @include box-shadow(0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12));
    @include transition-all;

      //@include media-breakpoint-up(xxl) {
      //  min-height: 246px;
      //}

    &:hover {
      @include box-shadow(0 4px 4px rgba(10, 16, 20, .24), 0 0 8px rgba(10, 16, 20, .12));
      // cursor: pointer;
      @include transition-all;
      cursor: pointer;

      //img {
      //    opacity: .8;

      //.card-img-prod {
      //  -webkit-filter: brightness(90%);

      //}

      //.btn {
      //  color: $gray-200;
      //}

    }

      .fa {
        font-size: 1.6rem;
        &:hover {
          color: $gray-700;
        }
      }

    .card-header {
      background: transparent;
      border-bottom: 0;
      fill: $white;
    }

    .card-title {
      color: $gray-800;
      font-family: $headings-font-family;
      font-size: 1.5rem;
      // font-weight: 500;
      margin-top: 0;
      padding-top: 0;
      text-align: center;
      // min-height: 2.3em;
      // background-color: rgba(82,138,214,.2);
      // text-shadow: 0 1px 1px rgba(0, 0, 0, 1);
      text-transform: uppercase;
    }

    img {
      max-width: 100%;
      max-height: 250px;

        @include media-breakpoint-up(xxl) {
          max-height: 300px;
        }

      object-fit: contain;
      @include transition-all;

    }
    img[width] {
      width: auto; /* Defer to max-width */
    }
    img[width][height] {
      height: auto; /* Preserve aspect ratio */
    }


      //.card-img-prod {
      //
      //}

    //.card-img-prod {
    //  max-height: 100px;
    //
    //  @include media-breakpoint-up(md) {
    //    max-height: 150px;
    //  }
    //
    //  @include media-breakpoint-up(xl) {
    //    max-height: 300px;
    //  }
    //
    //  @include media-breakpoint-up(xxl) {
    //    max-height: 360px;
    //  }
    //
    //
    //
    //}

    .offerprice {
      color: $primary;
      font-family: $headings-font-family;
    //  background-color: $danger;
    //  border-radius: .2em;
      font-size: 1.4rem;
      font-weight: 700;
      padding: .1em .2em;
    }

    .fa-stack {
      width: 3em;
      height: 3em;
      line-height: 3.5em;
    }

    .fa-stack-1x {
      color: $white;
    }


    .card-body {
      padding: .5em .2em 0.5em .2em;
      @include flexbox();
      @include align-items(flex-end);
      @include justify-content(center);
    }


    .card-footer {
      background-color: transparent;
      //background: linear-gradient(8deg, rgba(142,138,224,.8), rgba(255,0,0,0) 76.71%),
      //            linear-gradient(-8deg, rgba(203,118,119,.8), rgba(0,255,0,0) 70.71%);
      border-top: 0;
      padding-bottom: $grid-gutter-width;
      padding-top: 0;

      .btn {
        .fa {
          font-size: 1rem;
        }

        &:hover {
          color: $gray-200;
        }

      }



      //[class*="col-"] {
      //  padding: 0;
      //}




    }

   // .fa-stack-1x { line-height: inherit; }
   // .fa-stack-2x { font-size: 3.3em; }
  }
}

.product-list-search {
  // background-color: $white;
  .card {
    .card-header {
      fill: $white;
      height: 100%;
      //@include flexbox;
      padding: 0;
      //@include align-items(center);
      //@include justify-content(center);
    }
  }

  //background: linear-gradient(8deg, rgba(82,138,214,.8), rgba(255,0,0,0) 50.71%),
  //linear-gradient(-8deg, rgba(203,118,119,.8), rgba(0,255,0,.04) 30.71%);
  //.btn-group {
  //  border: 3px solid $white;
  //}
  .btn-nav {
    .btn-sm {
      margin-bottom: 0;
      color: $white;
      font-weight: 500;
      font-size: .9rem;
      @include transition-all;
      //padding-top: .45em;
      //padding-bottom: .45em;
    }

    .dropdown-toggle {
      text-transform: uppercase;
    }

    //.mat-form-field-wrapper {
    //  margin-top: .35em;
    //
    //  @include media-breakpoint-up(xxl) {
    //    margin-top: .35em;
    //  }
    //
    //  padding-bottom: 0;
    //  background: rgba(255,255,255, .3);
    //}
    //
    //.mat-form-field-infix {
    //  border-top: 2px solid transparent;
    //  padding: 0.586em 0;
    //}
    //
    //.mat-select-arrow {
    //  margin: 8px 0 0 4px;
    //}
    //
    //.mat-form-field-label {
    //  margin-top: .48em;
    //  color: $gray-300;
    //  text-transform: uppercase;
    //  font-size: .9rem;
    //  // font-family: Dosis, sans-serif;
    //}

  }
  //.fa-angle-down {
  //  padding-top: 1em;
  //}

  .flttxt {
    line-height: 2.5;
    margin-right: $spacer;
    color: $gray-400;
    text-transform: uppercase;
    font-size: .85rem;
  }


  .card {
    width: 100%;
    margin-bottom: $spacer * 4;
    border: 0;
    background: linear-gradient(10deg, rgba(82,138,214,.1), rgba(255,255,255, 1) 40.71%),
    linear-gradient(-8deg, rgba(203,118,119,.05), rgba(0,255,0,.05) 30.71%);

    // @include box-shadow(0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12));
    @include transition-all;

    //@include media-breakpoint-up(xxl) {
    //  min-height: 246px;
    //}

    &:hover {
      @include box-shadow(0 4px 4px rgba(10, 16, 20, .24), 0 0 8px rgba(10, 16, 20, .12));
      // cursor: pointer;
      @include transition-all;
      cursor: pointer;

      //img {
      //    opacity: .8;

      //.card-img-prod {
      //  -webkit-filter: brightness(90%);

      //}

      //.btn {
      //  color: $gray-200;
      //}

    }

    .fa {
      font-size: 1.6rem;
      &:hover {
        color: $gray-700;
      }
    }

    .card-header {
      background: transparent;
      border-bottom: 0;
      fill: $white;
    }

    .card-title {
      color: $gray-800;
      font-family: $headings-font-family;
      font-size: 1.5rem;
      // font-weight: 500;
      padding-top: 0;
      margin-top: 0;
      text-align: center;
      // min-height: 2.3em;
      // background-color: rgba(82,138,214,.2);
      // text-shadow: 0 1px 1px rgba(0, 0, 0, 1);
      text-transform: uppercase;

    }

    img {
      max-width: 100%;
      object-fit: contain;
      @include transition-all;

    }
    img[width] {
      width: auto; /* Defer to max-width */
    }
    img[width][height] {
      height: auto; /* Preserve aspect ratio */
    }


    //.card-img-prod {
    //
    //}

    //.card-img-prod {
    //  max-height: 100px;
    //
    //  @include media-breakpoint-up(md) {
    //    max-height: 150px;
    //  }
    //
    //  @include media-breakpoint-up(xl) {
    //    max-height: 300px;
    //  }
    //
    //  @include media-breakpoint-up(xxl) {
    //    max-height: 360px;
    //  }
    //
    //
    //
    //}

    .offerprice {
      color: $primary;
      font-family: $headings-font-family;
      //  background-color: $danger;
      //  border-radius: .2em;
      font-size: 1.4rem;
      font-weight: 700;
      padding: .1em .2em;
    }

    .fa-stack {
      width: 3em;
      height: 3em;
      line-height: 3.5em;
    }

    .fa-stack-1x {
      color: $white;
    }



    .card-body {
      padding: 2.5em .2em 0.5em .2em;
      @include flexbox();
      @include align-items(flex-end);
      @include justify-content(center);
    }


    .card-footer {
      background-color: transparent;
      //background: linear-gradient(8deg, rgba(142,138,224,.8), rgba(255,0,0,0) 76.71%),
      //            linear-gradient(-8deg, rgba(203,118,119,.8), rgba(0,255,0,0) 70.71%);
      border-top: 0;
      padding-bottom: $grid-gutter-width;
      padding-top: 0;

      .btn {
        .fa {
          font-size: 1rem;
        }

        &:hover {
          color: $gray-200;
        }

      }



      //[class*="col-"] {
      //  padding: 0;
      //}




    }

    // .fa-stack-1x { line-height: inherit; }
    // .fa-stack-2x { font-size: 3.3em; }
  }
}


// ACCORDION

.panel-open {
  .card {
   // background-color: lighten($yellow, 48%);
    .card-header {
      background-color: lighten($gray-100, 4%);

    }

    .form-check-label {
      color: $black;
    }

    .form-text {
      color: $black;
    }


    &:disabled {
      pointer-events: none;
      filter: none;
      opacity: 1 !important;
    }
  }
}

.custom-accordion {
  border-radius: 0;
  font-family: $font-family-sans-serif;
  margin-bottom: $spacer;
  .card-header {
    font-weight: 700;
    border-radius: 0;
    .form-text {
      font-weight: 500;
    }

  }

.badge {
  font-size: .85rem;
  width: 80px;
  cursor: default;
}

  input {
    background-color: $white;
  }
  //
  //.panel-enabled {
  // background-color: $success;
  //}

}


// ARTICLE DETAIL
.article-detail {

  h1 {
    text-align: center;
    font-family: $headings-font-family;
    font-weight: 700;
    font-size: 2rem;
    color: $blue;
    padding-bottom: $grid-gutter-width;
  }



  .article-content {
    background-color: $white;
    padding: $grid-gutter-width * 2 $grid-gutter-width $grid-gutter-width $grid-gutter-width;


    @include media-breakpoint-up(xl) {
      margin-top: $grid-gutter-width;
      @include border-radius($border-radius);
      padding: $grid-gutter-width * 2 $grid-gutter-width * 2;
    }

    @include media-breakpoint-up(xxxl) {
      padding: $grid-gutter-width * 2 $grid-gutter-width * 3.5;
    }

    p {
      font-size: 1rem;
      line-height: 1.45;
      font-family: $font-family-sans-serif;
    }

    .table {
      margin-bottom: $grid-gutter-width;

      th {
      font-size: .75rem;
      color: $white;
      text-transform: uppercase;
      font-weight: 700;
      background-color: $blue;
      border-color: transparent;
      font-family: $headings-font-family;
        @include media-breakpoint-up(xl) {
          font-size: .85rem;
        }
      }

      td {
        font-size: 1rem;
        border-color: $gray-100;
        font-family: $font-family-sans-serif;
      }
    }

  }

}


// SIZE CHART

.table{
  empty-cells: show;

  td {
    text-align: center;
    background-color: unset;
  }

  .smalldigits {
    font-size: 15px;
    padding: 10px 15px
  }



  .gray {
    background-color: $gray-300;
  }

  .lightgray {
    background-color: $gray-200;
  }

  tbody tr:nth-child(odd) td, tbody tr:nth-child(odd) th {
    background-color: $gray-100;
  }

}




// PRODUCT DETAIL

.gallery-container {
  border: 3px solid $warning;
 // max-height: 40vh;

  img {
    opacity: 1;

    &:hover {
      opacity: .7;
    }
  }

  .img-fluid {
    margin-bottom: 1px;
    // border: 1px solid $gray-200;
    @include transition-all();
    &:hover {
      // border-color: $gray-600;
      cursor: pointer;
    }
  }

  .active {
    border-color: $black;
    opacity: .5;
    // @include box-shadow($box-shadow);

    &:hover {
      border-color: $black;
      cursor: default;
      opacity: .5;
    }
  }

  @include media-breakpoint-up(md) {
    .scroll-block {
      max-height: 75vh;
    }
  }
}



    .color-chooser-container {
      float: left;
      margin: 4px 8px 4px 0;

      //@include media-breakpoint-up(lg) {
      //  margin: 4px 6px 4px 0;
      //}
      //
      //@include media-breakpoint-up(xxl) {
      //  margin: 0 .35em .35em 0;
      //}


      .color-chooser {
        border: 1px solid $gray-200;
        height: 46px;
        width: 46px;
        @include border-radius(50%);
        @include transition-all();
        background: $gray-200;


        &:hover {
          border-color: $gray-600;
          cursor: pointer;
        }

    }

      .color-chooser.active  {
        border-color: $primary;
       // filter: brightness(.6);

        border-style: solid;
        box-shadow: 0 3px 1px -2px rgb(210 70 45 / 34%), 0 2px 2px 0 rgb(210 70 45 / 28%), 0 1px 5px 2px rgb(210 70 45 / 90%);

        //box-shadow: rgba(0, 0, 0, .25) 0 5px 6px, rgba(0, 0, 0, .22) 0 3px 3px;
        &:hover {
          border-color: $primary;
        }
      }

  }




.button-size {
  .btn {
    background-color: $gray-100;
    background-position: center;
    border-color: $gray-400;
    color: $black;
    // font-family: "Roboto Mono", sans-serif;
    //font-size: .85rem;
    //font-weight: 400;
    padding: .2em;
    // margin: .3em .3em 0 0;
    // width: 90px;
  //  text-shadow: 0 2px 2px rgba(255, 255, 255, 0.9);

    @include justify-content(center);
    @include transition-all();
    transition: background .3s;
    width: 100%;

    &:hover {
      background: $primary radial-gradient(circle, transparent 1%, rgba(255,255,255,.3) 1%) center/15000%;

      cursor: pointer;
    }

    &:active {
      background-color: $primary;
      color: $white;
      background-size: 100%;
      transition: background 0s;
    }

    &:focus {
      outline: none !important;
      border: 1px solid $gray-700;
      box-shadow: 0 0 10px $gray-500;
    }

  }

  .not-aviable {
    background-color: $gray-300;
  }

  .active {
    background-color: $primary;
    transition: background 0s;
    color: $white;
  }
}


.container-favourite-button  {
  position: absolute;
  right: .5em;
  z-index: 2;

  .fa-stack {
    &:hover {
      .fa-circle {
      color: lighten($red, 20%);
      }
    }
  }

  .fa-heart-add, .fa-heart-remove{
    color:  $red;
    margin-top: .1em;
  }

  .fa-circle {
    color: lighten($red, 30%);
  }
}

.container-review {
  width: 100%;

  //@include media-breakpoint-up(xxl) {
  //  width: 75%;
  //  margin-left: auto;
  //  margin-right: auto;
  //}

  hr {
    margin-top: 1em;
  }

  ::placeholder {
    color: $gray-200;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $gray-200;
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    color: $gray-200;
  }

  input[type=text] {
    width: 100%;
    margin-bottom: 1em;
    @include media-breakpoint-up(md) {
      width: 50%;
    }
  }

  h3 {
    font-family: $font-family-sans-serif;
    font-weight: 700;
span {
  margin-right: 1em;
  vertical-align: bottom;
}
  }

  .input-rating {
    .input-rating__btn {
      background-color: $primary;
    }
  }
}



.star {
  fill: $gray-200 !important;
}

.full .star, .star-half {
  fill: $orange !important;
}

.review-txt {
  height: 100px;
  border-radius: unset;
}

.product-detail {
  margin-bottom: $grid-gutter-width;


  h1 {
    margin: 0;

    @include media-breakpoint-up(xxxl) {
      margin-bottom: 0.5em;
    }
  }


  .rating {
    padding-bottom: 1em;

    @include media-breakpoint-up(xxl) {
      padding-bottom: 0;
      padding-top: .75;
    }

    @include media-breakpoint-up(xxxl) {
      padding-top: .85em;
    }



    .input-rating {
      .input-rating__btn {
        background-color: $gray-300;
        width: 25px;
        &:hover {
          cursor: help;
        }
      }
    }
  }


  img {

    max-width: 100%;
    @include transition-all;
    object-fit: contain;

    //@include media-breakpoint-up(lg) {
    //  max-width: 100%;
    //}
    //
    //@include media-breakpoint-up(xl) {
    //  max-width: 70%;
    //}
    //
    //
    //@include media-breakpoint-up(xxl) {
    //  max-width: 80%;
    //}
    //
    //
    //@include media-breakpoint-up(xxxl) {
    //  max-width: 100%;
    //}

    &:hover {
      cursor: pointer;
     // opacity: .8;
    }

  }
  img[width] {
    width: auto; /* Defer to max-width */
  }
  img[width][height] {
    height: auto; /* Preserve aspect ratio */
  }

  //.product-img {
  //
  //  @include media-breakpoint-up(lg) {
  //    max-height: 300px;
  //  }
  //
  //  @include media-breakpoint-up(xl) {
  //    max-height: 320px;
  //  }
  //
  //  @include media-breakpoint-up(xxl) {
  //    max-height: 380px;
  //  }
  //
  //  @include media-breakpoint-up(xxxl) {
  //    max-height: 400px;
  //  }
  //  z-index: 2;
  //  //@include box-shadow($box-shadow)
  //}

  .img-zoom-result {
    @include box-shadow($box-shadow-lg);
    z-index: 2;
  }

  .badge {
    font-size: 1.2em;
  }


  .prod-card {
    background-color: $white;
    // margin: $grid-gutter-width;
    padding-bottom: $grid-gutter-width * 2;
    padding-top: 0;

    @include media-breakpoint-up(xxl) {
      margin: $grid-gutter-width 0 0 0;
    }

    @include media-breakpoint-up(xxxl) {
      margin: 0;
    }


  }

  .prod-detail-img-container, .prod-detail-img-container-lg {
    @include flexbox();
    @include align-items(flex-center);
    @include justify-content(center);
    padding: $grid-gutter-width;
    @include transition-all;

    img {
      @include transition-all;
    }
    &:hover {
      img {
        cursor: zoom-in;
        transform: scale(1.05);
      }
    }
  }

  .prod-detail-img-container-lg {
    @include media-breakpoint-up(xxl) {
      display: none;
    }
  }

  .prod-detail-img-container {
    display: none;
    @include media-breakpoint-up(xxl) {
      display: unset;
    }
  }

  .price-block {
    background-color: lighten($yellow, 28%);
    padding: .2em .3em;
    text-align: center;
  }

  .prod-detail-content-container {
    position: relative;
  //  overflow-x: hidden;
  //  width: 100%;

    .price-container {
      padding: 1em 2em;
      position: relative;
      //display: inline-flex;

      @include media-breakpoint-up(lg) {
        margin-top: -1em;
      }

      .star-text {
        color: $white;
      }

      .starburst {
        margin-top: -1em;

        @include media-breakpoint-up(lg) {
          margin-top: -3em;
        }

        .burst-one, .burst-one:before, .burst-two, .burst-two:before {
          background: $secondary;
        }
      }

      h5 {
        margin-top: 1em;
        margin-bottom: $spacer * 2;
        text-align: center;

        span {
          margin-right: 1.2em;
          small {
            font-size: 1rem;
            width: 100%;
          }

          background-color: $orange-100;

          border: 1px dashed $red-400;
          // background: linear-gradient(8deg, $orange-200, $white 90.71%);
          font-family: $font-family-sans-serif;
          font-size: 1.8rem;

          font-weight: 500;

          @include media-breakpoint-up(md) {
            font-size: 2rem;
          }

          @include media-breakpoint-up(xl) {
            font-size: 2.2rem;
          }

          //@include border-radius($border-radius);
          padding: .25em .75em;
          // white-space: nowrap;
          // @include box-shadow(0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12));
        }
      }
    }

    .quantity-container {
      font-family: $headings-font-family;
      margin-bottom: 0;
      margin-top: 1em;
      @include flexbox();
      // @include align-items(flex-center);
      @include justify-content(center);

      h5 {
        // float: left;
        margin-top: 0;
        padding: .18em .25em 0 .5em;
        //vertical-align: text-top;
      }
    }

    .availability {
      font-weight: 600;
    }

    .mat-content-container {
      margin-left: .25em;
    }

    .price-input {
      width: 40px;
    }

    .offerprice {
      color: $secondary;
      //  background-color: $danger;
      //  border-radius: .2em;
      font-size: 1.4rem;
      font-weight: 700;
      padding: .1em .2em;
    }

    .prod-detail-buttons {
      [class*="col-"] {
        height: 35px;
        padding: 0;
      }
    }

    .btn-group {
      color: $white;
      margin-bottom: 1em;
      margin-top: .3em;
      //@include media-breakpoint-up(xxl) {
      //  margin-top: .3em;
      //}
      // height: 2.2em;
    }

    .btn-danger {
      color: $white;
      margin-bottom: 1em;
      margin-top: .2em;
      //@include media-breakpoint-up(xxl) {
      //  margin-top: .3em;
      //}
      // height: 2.2em;
    }

    //.prod-table {
    //  // margin-top: $grid-gutter-width;
    // // background-color: $white;
    //  // padding: $grid-gutter-width;
    // // font-family: $headings-font-family;
    //  font-size: .85rem;
    //  margin-left: 0;
    //
    //  .lead {
    //    font-size: 1.2rem;
    //    font-weight: 700;
    //  }
    //
    //  //[class*="col-"] {
    //  //  padding-bottom: 0;
    //  //
    //  //}
    //
    //
    //
    //  table {
    //    border: 0;
    //    margin-left: $spacer * 2;
    //    td{
    //      padding: 0 1em 0 0;
    //    }
    //
    //    span {
    //      margin-left: $spacer * 2;
    //    }
    //  }
    //
    //  .fa-check {
    //    color: darken($teal, 15%);
    //  }
    //
    //  .fa-remove {
    //    color: $danger;
    //  }
    //
    //  .fa-check, .fa-remove {
    //    padding-top: .5em;
    //  }
    //
    //  .fa-cart-plus {
    //   // margin-top: .6em;
    //   // line-height: 0.6;
    //    &:hover {
    //      color: $white;
    //    }
    //  }
    //
    //  .table-header {
    //   // border-top: 1px solid $gray-200;
    //   // border-bottom: 1px solid $gray-200;
    //    font-weight: 700;
    //   // margin-top: $grid-gutter-width;
    //  }
    //
    //  .table-header, .table-col {
    //    padding: .5em 0 0 .5em;
    //  }
    //
    //  //.prod-detail-buttons {
    //  //  padding-top: 0.5em;
    //  //  padding-left: 0.5em;
    //  //}
    //
    //  //.table-col {
    //  //
    //  //}
    //
    //  //[class*="col-"] {
    //  //  padding: 0.5em;
    //  //}
    //}
  }

  .divider {
    // border-bottom: 1px solid $gray-200;
    background-color: $gray-200;
    height: 1px;
    padding: 0;
  }

  .cart-total-container {
   // padding: 0 0 0 $grid-gutter-width;
   // background-color: transparent !important;
    padding: $grid-gutter-width 0 $grid-gutter-width 0;

    @include media-breakpoint-up(md) {
      padding: $grid-gutter-width $grid-gutter-width * 4;
    }

    @include media-breakpoint-up(lg) {
      padding: $grid-gutter-width $grid-gutter-width * 6;
      display: inline-block;
    }

    @include media-breakpoint-up(xl) {
      padding: 0 $grid-gutter-width * 6;
    }

    @include media-breakpoint-up(xxl) {
      padding: $grid-gutter-width 0 $grid-gutter-width 0;
    }

    //@include media-breakpoint-up(xxxl) {
    //  padding: $grid-gutter-width 0 $grid-gutter-width $grid-gutter-width * 2;
    //}




  .cart-total {
   // width: 100%;

    padding: 0 $grid-gutter-width;

    @include media-breakpoint-up(lg) {
      padding: $grid-gutter-width;
    }

    @include media-breakpoint-up(xl) {
      padding: $grid-gutter-width;
      background-color: rgba(255,255,255, .2);
      @include border-radius($border-radius-sm);
    }

    .lead {
      font-size: 1.3rem;
      font-weight: 700;

      @include media-breakpoint-up(xl) {
        font-size: 1.1rem;
      }

      @include media-breakpoint-up(xxl) {
        font-size: 1.3rem;
      }

      @include media-breakpoint-up(xxxl) {
        font-size: 1.4rem;
      }



    }


   // @include box-shadow(0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12));

    h5 {
      text-align: center;
      text-transform: uppercase;
      color: $gray-500;
    }



    .btn {
      margin-top: 1em;
    }

    .row {
     // width: 100%;
    //  padding: 0;
      margin-left: $spacer;
      margin-right: $spacer;
      padding-top: $grid-gutter-width;
      padding-bottom: $grid-gutter-width;
      font-family: $font-family-sans-serif;

      [class*="col-"] {
        padding: 0;
      }
    }

  }
  }
}


// cart price-input to

.price-input {
  border-color: $primary;
  border-left: 0;
  border-radius: 0;
  border-right: 0;
  color: $black;
  font-size: .85rem;
  font-weight: 500;
  height: 100%;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
  // width: 40px;
}







// ASIDE, USER NAV //////////////////////////////////

.user-nav-container {
  @include media-breakpoint-up(xxl) {
    padding-left: $grid-gutter-width * 2;
    padding-top: $grid-gutter-width;
  }

  @include media-breakpoint-up(xxxl) {
    padding-left: $grid-gutter-width * 3;
  }
}

.aside, .user-nav-container {

  padding-top: $spacer * 3;
  //@include media-breakpoint-up(lg) {
  //  padding-top: 1.35em;
  //}


  .list-unstyled {
    li {
      padding: 0;
    }
  }

  .list-group {

   // @include box-shadow($box-shadow-lg);

    border: 0;

    .list-group-item {
      background-color: transparent;
      border-color: $gray-200;
      border-left: 0;
      border-right: 0;
      font-family: $font-family-sans-serif;
      font-size: .85rem;
      padding-bottom: $spacer * 2;
      padding-top: $spacer * 2;
    }

    .active {
      // background-color: rgba(82,138,214,.2);
     // background: linear-gradient(8deg, rgba(82,138,214,.8), rgba(255,0,0,0.2) 90.71%);
      border-top: 0;
      color: $gray-700;
      font-weight: 800;
      letter-spacing: .05em;
      text-transform: uppercase;
    }

    .disabled {
      background: $gray-200;
      color: $black;

      &:before  {
        color: $black;
        content: "\f054";
        display: inline-block;
        font-family: 'FontWebshop', sans-serif;
        font-size: .65rem;
        font-style: normal;
        font-weight: normal;
        padding-right: .25em;
      }
    }

    .list-group-item-action {
      // background: rgba(255,255,255, .1);
      @include transition-all;
      &:hover {
        background: rgba(73,66,60, 0.1);
        cursor: pointer;
      }
    }
  }
}

.aside {

  .list-group-item-action {
    border-radius: 0;
    @include transition-all;
    &:hover {
      background: rgba(73,66,60, 0.1);
      cursor: pointer;
    }

    &:before  {
      color: $primary;
      content: "\f054";
      display: inline-block;
      font-family: 'FontWebshop', sans-serif;
      font-size: .65rem;
      font-style: normal;
      font-weight: normal;
      padding-right: .25em;
    }

  }
}

.aside-filters {

  //@include media-breakpoint-up(lg) {
  //  padding-top: 1.35em;
  //}

  margin-bottom: $grid-gutter-width;
        h3 {
          border-top: none;
          color: $gray-700;
          font-family: $font-family-sans-serif;
          font-size: .85rem;
          font-weight: 800;
          letter-spacing: .05em;
          padding-left: 0;
          padding-top: 1.65em;
          text-transform: uppercase;

          @include media-breakpoint-up(lg) {
            padding-top: 1.65em;
          }

          .fa {
            font-size: 1.3rem;
            color: $gray-400;
            &:hover {
              cursor: default;
            }
          }

        }

  .alert {
    background-color: $primary;
    box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    color: $white;
    cursor: pointer;
    font-family: $headings-font-family;
    @include transition-all;
    margin-bottom: .4em;
    padding: .45em 1em .35em 1em;
    text-transform: uppercase;
    font-size: .85rem;

    &:hover {
      background-color: $secondary;
    }
    .fa {
      font-size: 1.35rem;
    }

    //&:hover {
    //  background-color: $gray-100;
    //}

  }
}

.user-nav-container {

  .disabled {
    &:before {
      content: none !important;
    }
  }

  .list-group {
    border-radius: 0;
  }

  .fa {
    width: 22px;
    color: $primary;
    &:hover {
      color: darken($primary,10%);
    }
  }
}

// USER EDIT ///////////////////////////////////////

.user-edit-container {
  background-color: $white;
  padding: $grid-gutter-width;
  @include border-radius($border-radius-sm);
}

.orders-container {

  padding: $grid-gutter-width $grid-gutter-width / 2 0 $grid-gutter-width / 2;

  /* Product entries */
  .product {
    border-bottom: 1px solid $gray-200;
    margin-bottom: 10px;
    padding-bottom: 5px;

    h3 {
      margin: 0 0 $spacer -10px;
      padding: 0;
    }

    .product-image {
    //  width: 20%;
      float: left;
      height: 40px;
      padding: $spacer $spacer * 3 $spacer $spacer;
      width: 40px;
      @include flexbox();
      @include align-items(center);
      @include justify-content(center);

      @include media-breakpoint-up(md) {
        height: 60px;
        width: 60px;
      }

      img {
        max-height: 100%;
        max-width: 100%;
      }
    }

    .product-description {
      margin: 5px 20px 5px 0;
      line-height: 1.4em;
    }

  }



  h3 {
    text-transform: uppercase;
    color: $black;
    font-weight: 700;
    padding-bottom: 0;
    font-family: $headings-font-family;
  }

  .text-danger {
    font-size: 1.2rem;
  }

}

.rates-container {
  padding: 0 $grid-gutter-width / 2 0 $grid-gutter-width / 2;

  .row {
    border-bottom: 1px solid $gray-200;
    padding-bottom: $grid-gutter-width / 2;
    padding-top: $grid-gutter-width / 2;

    &:last-child {
      border-bottom: 0;
    }
  }

  .product-title {
    margin: 0 0 0 6px;
    padding-top: $spacer;
  }

  .product-description {
    line-height: 1.4em;
    margin: 5px 20px 5px 6px;
  }

  .text-muted {
    margin-left: 6px;
  }

  h3 {
    text-transform: uppercase;
    color: $black;
    font-weight: 700;
    padding-bottom: 0;
    font-family: $headings-font-family;
  }

}

.orders-container, .rates-container, .favourites-container {
  background: $white;

  img {
    max-height: 100px;
  }

  @include media-breakpoint-up(sm) {
    img {
      max-height: 120px;
    }
  }



  .product-title, .card-title {
    font-family: $headings-font-family;
    font-weight: 700;
    margin-right: 20px;
    text-transform: uppercase;
    display: block;
    font-size: 1rem;
  }

}

.favourites-container {
  .card {
    @include box-shadow(0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12));
    @include transition-all;

    &:hover {
    cursor: pointer;
      @include box-shadow(0 4px 4px rgba(10, 16, 20, .24), 0 0 8px rgba(10, 16, 20, .12));

    }
  }
}

// BRANDS ///////////////////////////////////////////

.brands {
  @include box-shadow(0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12));
  background-color: $white;
  padding: $grid-gutter-width;
  @include border-radius($border-radius);

  h2 {
    text-transform: uppercase;
    text-alig: center;
  }


  //.active {
  //  margin-bottom: $grid-gutter-width;
  //}
  //
  //@include media-breakpoint-up(xl) {
  //  margin-top: $grid-gutter-width * 2;
  //}

  img {
    max-width: 100%;
    margin-bottom: $grid-gutter-width / 2;
    object-fit: contain;
    @include transition-all;
    // filter: grayscale(100%);


    &:hover {
      cursor: pointer;
      opacity: .8;
    }

  }
  img[width] {
    width: auto; /* Defer to max-width */
  }
  img[width][height] {
    height: auto; /* Preserve aspect ratio */
  }
}

.brand {
  max-height: 150px;
}

body.modal-open {
  overflow: hidden;
  position: fixed;
}

.modal-info {
  p {
    text-align: left !important;
  }

  h2 {
    color:  $black !important;
    font-size: 1.2rem !important;
  }

  .mat-accordion {

    .mat-expansion-panel:not([class*='mat-elevation-z']) {
      border-radius: 0;
    }



    .mat-expansion-panel-header {
      @include transition-all;
      border-radius: 0;
     // height: 2.6em !important;
      font-family: $font-family-sans-serif;

      //&:hover {
      //  background: $gray-100;
      //}

      .mat-expansion-panel-header-title {
        font-size: .95rem;
        color: $black;
        font-weight: 600;
      }
    }

    .mat-expansion-panel-body {

    }
  }
}

/* modal animation */
.modal-content{
  animation-duration: 0.6s;
  animation-name: openmodal;
  padding: $spacer;
 //  @include box-shadow(0 4px 4px rgba(10, 16, 20, .24), 0 0 8px rgba(10, 16, 20, .12));
 @include box-shadow($box-shadow-lg);

  @include media-breakpoint-up(xl) {
    margin-bottom: $grid-gutter-width * 2;
    padding: $grid-gutter-width;
  }
}

@keyframes openmodal {
  //0%   {transform: scale(2)}
  //100% {transform: scale(1)}

  0%   {transform: translateY(2000px);}
  50%   {transform: translateY(2000px);}
  100% {transform: translateY(0);}
}


.modal {

  //.btn {
  //  font-family:  $headings-font-family;
  //  // font-size: .65rem;
  //  font-weight: 700;
  //  text-transform: uppercase;
  //}
 // background-color: rgba(121, 177, 204, 0.85);
  //background: linear-gradient(10deg, rgba(82,138,214,.8), rgba(255,255,255, 1) 40.71%),
  //linear-gradient(-8deg, rgba(203,118,119,.8), rgba(0,255,0,1) 30.71%);
 //  background: linear-gradient(10deg, lighten($blue, 20%), lighten($teal, 20%), lighten($blue, 20%) 90.71%);
 // background: linear-gradient(10deg, lighten(rgba(243, 220, 212, 0.85), 10%), lighten(rgba(242, 227, 141, 0.85), 20%), lighten(rgba(243, 220, 212, 0.85), 10%) 90.71%);
 // opacity: .9;




  @media only screen and (min-device-width : 1690px) {
    background: url("/assets/images/logo-white.png") 25px 20px no-repeat $primary;
  }

  // background-image: url("/assets/images/logo-white.png");
 //  background-color: $primary;
  // background-position: left;
  // background-repeat: repeat;

  .btn-secondary {
    color: $white;
  }

 .modal-footer {
   padding: 0 $grid-gutter-width;
    border: 0;
  }

  .fa-times {
    &:hover {
      cursor: pointer;
      color: $gray-300;
    }
  }

  .modal-body {
    padding: $grid-gutter-width;
  }

  h4 {
    font-weight: 600;
    margin-bottom: .25em;
  }

  p {
    font-family: $font-family-sans-serif;
    text-align: center;
    span {
      font-weight: 700;
      border-bottom: 3px solid $warning;
    &:hover {
      cursor: pointer;
      color: $info;
      border-bottom: 3px solid $info;
    }

    }

  }

  input {
    font-size: 1.5rem;
  }

  .modal-header {
    border: 0;
    padding-bottom: 0;

    .modal-title {
      color: $gray-600;
    }
  }

  .fa-facebook {
    vertical-align: middle;
  }

  .modal-footer {
    background-color: lighten($blue, 25%);
  }

}

input { -webkit-user-select:text;}

.login-input-container {
  display: flex;
  flex-direction: column;
}

.login-input-container > * {
  width: 100%;
}

.form-box {
  width: 100%;
  max-width: 290px;
  padding: 0 15px 15px 15px;
  margin: 0 auto;

  @media only screen and (min-device-width : 360px) {
    max-width: 340px;
  }

  @media only screen and (min-device-width : 375px) {
    max-width: 350px;
  }
  @media only screen and (min-device-width : 414px) {
    max-width: 390px;
  }

  @media only screen and (min-device-width : 468px) {
    max-width: 420px;
  }
}

.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-control {
  background-color: transparent;
}

.mat-expansion-panel {
  background-color: transparent;
}


.pagination-next a:before  {
  color: $indigo;
  content: "\f054";
  display: inline-block;
  font-family: 'FontWebshop';
  font-size: .65rem;
  font-style: normal;
  font-weight: normal;
  padding-right: .25em;
}
.pagination-next a:after  {
  content: '' !important;
  margin-left: 0 !important;
}
.pagination-next a  {
  text-decoration: none;
}

/* ======= ALBUM, ARTICLE LIST ======= */

.album {

  margin-top: $grid-gutter-width;

  .card {
    background-image: linear-gradient(lighten($teal, 35%), lighten($blue, 25%));
   // @include box-shadow(0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12));
    @include box-shadow($box-shadow);

    margin-bottom: $grid-gutter-width;

    a {
      color: $primary;
      text-decoration: none;
    }

  .card-header {
    background-color: $blue;
    color: $white;
    letter-spacing: .05em;
    padding-bottom: .25em;
    padding-top: .25em;
    text-transform: uppercase;
  }

    .card-body {
      padding-top: $spacer;
      padding-bottom: 0;
    }

    .card-footer {
      background-color: transparent;
      border: 0;
      padding-top: 0;
    }

  }
}


// Scrolled to top button
.scrolled {
  a {
    background-color: $secondary;
    border: 2px solid $white;
    border-radius: 50%;
    bottom: 20px;
    color: $white;
    cursor: pointer;
    display: block;
    font-size: 24px;
    font-weight: 600;
    height: 50px;
    line-height: 45px;
    opacity: 1;
    padding-top: .2em;
    position: fixed;
    right: 20px;
    text-align: center;
    text-decoration: none;
    width: 50px;

    @include transition-all();

    @include media-breakpoint-up(md) {
      bottom: 25px;
      right: 25px;
    }

    @include media-breakpoint-up(lg) {
      bottom: 30px;
      right: 30px;
    }

    &:hover {
      background-color: $gray-500;
    }
  }
}

.page-scroll-bottom-container {
  z-index: 10000;
}



// STEPPER CHECK OUT
.stepper {
  background-color: unset;
  @include border-radius($border-radius-lg);
  margin-top: $grid-gutter-width;


  padding-left: 0;
  padding-right: 0;
  @include border-radius(0);

  @include media-breakpoint-up(lg) {
    @include border-radius($border-radius-lg);
  }

  //.mat-icon-no-color {
  //  display:  none;
  //}

  .checkout-over {
    font-size: .85rem;
    margin-top: 0;
    background-color: $warning;
    padding: $spacer * 2;
    color: $info;
    border: 1px solid $info;
    @include transition-all;

    h6 {
      font-size: .85rem;
      font-weight: 700;
      margin-bottom: 0;
      text-transform: uppercase;
    }

    &:hover {
      cursor: pointer;
      opacity: .9;
    }

  }

  //.text-truncate {
  //  border-radius: 0;
  //}


  h3 {
   font-size: 1.2rem;

    @include media-breakpoint-up(md) {
      font-size: 1.35rem;
    }
  }

  .mat-step-icon {
    border-radius: 50%;
    height: 18px;
    position: relative;
    width: 18px;

    @include media-breakpoint-up(md) {
      height: 24px;
      width: 24px;
    }
  }

  .mat-step-label {
    display: inline-block;
    min-width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    white-space: nowrap;
  }

  .mat-stepper-horizontal-line {
    min-width: 12px;
  }

  .mat-step-text-label {
    color: $gray-400;
    font-family:  $headings-font-family;
    font-size: .65rem;
    font-weight: 700;
    text-transform: uppercase;

    @include media-breakpoint-up(sm) {
      font-size: .85rem;
    }

    @include media-breakpoint-up(md) {
      font-size: 1rem;
    }
  }

  .mat-horizontal-stepper-header .mat-step-icon {
    margin-right: 2px;
    @include media-breakpoint-up(sm) {
      margin-right: 8px;
    }
  }

  .mat-step-header {
    border-radius: $border-radius-lg;
  }

}



.company-panel {
  border: 0;

  .panel-heading {
    display: none;
  }

  .card {
    border: 0;
    margin: 0;
  }

  .panel-body, .card-body, .card-block {
    padding: 0 !important;
  }

  .panel-collapse {
    border: 0;
    padding-bottom: 0;
  }

  .collapse {
    display: none;
  }

}


.table-div-container {
  // border: 1px solid $secondary;
  // border: 0;
  // border-top: 4px solid $secondary;
  // margin-right: 12px;

  box-shadow: 0 0 2px rgba(0,0,0,0.3);

  .row {
    padding: 0;

    // Zebra rows
    &:nth-child(even) {
      background-color: $gray-100;
    }
    //  border-left: 1px solid $secondary;



    [class*="col"] {
      border-bottom: 1px solid $gray-300;
     // border-left: 1px solid $gray-300;
      // padding: .25em;
      padding-bottom: $spacer / 4;
      padding-top: $spacer / 4;
      @include flexbox();
      @include align-items(center);
    }
  }

  .table-div-header {
    border-left: 1px solid $primary;
    border-right: 1px solid $primary;
    border-top: 1px solid $primary;
    background-color: $primary;
    color: $white;

    [class*="col"] {
      border-color: darken($primary, 5%);
    }
  }

  .row-selected {
    @include transition-all();
    [class*="col"] {
      background-color: lighten($primary, 22%);
    }
    .col-highlighted, .col-mono {
      background-color: lighten($primary, 10%);
    }

    .col-input {
      background-color: lighten($primary, 22%);
      input {
        background-color: lighten($primary, 22%);
      }
      input:focus {
        background-color: lighten($cyan, 15%);
      }
    }

    .col-input-disabled {
      background-color: lighten($primary, 22%);



      input:disabled, .ng-select.ng-select-disabled > .ng-select-container {
        background-color: lighten($primary, 22%);
        .ng-arrow {
          display: none;
        }
      }

      input:disabled, .ng-select.ng-select-single > .ng-select-container  {
        background-color: lighten($primary, 22%);
        .ng-arrow {
          display: none !important;
        }
      }

      .ng-select {


        .ng-select-container {
          .ng-arrow {
            display: none !important;
          }
        }
        .ng-arrow-wrapper {
          .ng-arrow {
            display: none !important;
          }
        }
      }

      .ng-arrow-wrapper {

        .ng-arrow {
          display: none !important;
        }
      }



      .ng-select.ng-select-multiple > .ng-select-container {
        .ng-arrow {
          display: none;
        }
      }




    }

  }

  //.col-input-right-aligned {
  //
  //  input {
  //    text-align: right;
  //  }
  //}

}

//::ng-deep .mat-form-field-autofill-control {
//  display: none;
//}

//::ng-deep .countrySelector {
//  margin-right: 90px;
//}


.disabled-favourite-button {
  pointer-events: none;
  user-select: none;
}

input.ng-invalid.ng-dirty {
  border: 1px solid $red;
}


re-captcha.is-invalid > div {
  border: 1px solid #dc3545 !important;
  border-radius: 0.2rem;
}

.not-enough-product {
  background: $red-100;
}

.inactive-product {
  background: $gray-100;
}

.panel-group {
  border-radius: 0;
  .btn-link {
    text-align: left;
    text-transform: unset;
    border-radius: 0;
  }
  .card-header, .card {
    border-radius: 0;
  }

  .panel-body {
    padding: 1em;
  }

}
