// Responsive shopping cart //

/* Global "table" column settings */

// CHECK OUT ////////////////////////////////////////
.check-out {
 // background-color: $white;
 // margin-top: $grid-gutter-width / 2;
  padding: $grid-gutter-width / 2;


  @include media-breakpoint-up(xl) {
    padding: $grid-gutter-width $grid-gutter-width;
  }

  @include media-breakpoint-up(xxxl) {
    padding: $grid-gutter-width $grid-gutter-width * 2.5;
  }

  .cart-checkout {
    h3 {
      font-size: 1.2rem;
      margin-bottom: $grid-gutter-width;
      color: $gray-800;
      span {
        float: right;
      }
    }
  }


  .shopping-cart {
    // margin-top: -35px;

    width: 100%;

    .product-image { float: left; width: 26%}
    .product-details { float: left; width: 56%}


    @include media-breakpoint-up(sm) {
      .product-image { float: left; width: 14%}
      .product-details { float: left; width: 74%}
    }


    @include media-breakpoint-up(md) {
      .product-image { float: left; width: 10%}
      .product-details { float: left; width: 78%}
    }

    @include media-breakpoint-up(lg) {
      .product-image { float: left; width: 18%}
      .product-details { float: left; width: 56%}
    }

    @include media-breakpoint-up(xl) {
      .product-image { float: left; width: 18%}
      .product-details { float: left; width: 62%}
    }


    @include media-breakpoint-up(xxxl) {
      .product-image { float: left; width: 18%}
      .product-details { float: left; width: 62%}
    }



    //.product-price { float: left; width: 14%; text-align: right; padding-right: 3em}
    //.product-quantity { float: left; width: 12%; text-align: center}
    //.product-removal { float: left; width: 9%; text-align: center}
    .product-line-price {
      float: left;
      text-align: right;
      width: 20%
    }

    /* Column headers */
    .column-labels {
      .product-line-price {
        text-indent: -9999px;
      }
    }

    /* Product entries */
    .product {
      border-bottom: 1px solid $gray-200;
      margin-bottom: 10px;
      padding-bottom: 5px;
      // display: inline;

      .product-image-container {
        width: 60px;
        height: 60px;
        padding: .2em;
        background-color: #fff;
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);
      }

      .product-image {

        padding: $spacer $spacer * 2 $spacer 0;



        //@include media-breakpoint-up(lg) {
        //  width: 80px;
        //  height: 80px;
        //}


        img {
          max-height: 100%;
          max-width: 100%;
        }
      }

      .product-details {
        color: $gray-500;
        .product-title {
          margin-right: 20px;
          font-family: $headings-font-family;
          text-transform: uppercase;
          font-weight: 700;
          display: block;
        }
        .product-description {
          margin: 5px 20px 5px 0;
          line-height: 1.4em;
        }
      }

      //.product-quantity {
      //  input {
      //    width: 60px;
      //    color: black;
      //
      //  }
      //}

      .remove-product {
        border: 0;
        padding: 4px 8px;
        background-color: $primary;
        color: #fff;
        font-family: $headings-font-family;
        font-size: 12px;
        border-radius: 3px;
      }

      .remove-product:hover {
        background-color: #a44;
      }
    }


  }

  .header {
    font-size: 1.1rem;
    font-weight: 700;
  }

  .prod-img-container {
    width: 80px;
    height: 80px;
    padding: $spacer;
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
    // border: 1px solid $gray-100;
  }

  .product-img {
    max-height: 100%;
    max-width: 100%;
    //border: 1px solid $gray-100;
  }
}




/* This is used as the traditional .clearfix class */
.group:before,
.group:after {
  content: '';
  display: table;
}
.group:after {
  clear: both;
}
.group {
  zoom: 1;
}


/* Apply clearfix in a few places */
.shopping-cart, .column-labels, .product, .totals-item {
  @extend .group;
}

.shopping-cart-header {
  background-color: $gray-400;
}


/* Apply Ft signs */
//.product .product-price:after, .product .product-line-price:after, .totals-value:after {
//  content: ' Ft';
//}

label {
  color: $gray-500;
}


/* Column headers */
.column-labels {
  label {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid $gray-400;
  }

  .product-image, .product-details, .product-removal {
   text-indent: -9999px;
  }
}





/* Totals section */
.totals {
    label {
      width: auto;
      font-weight: 700;
    }
    .totals-value {
      width: 40%;
      font-weight: 700;
    }

  .totals-item {
    clear: both;
    float: right;
    margin-bottom: 10px;
    width: 100%;

    label {
      clear: both;
      float: left;
      margin-left: 80px;
    }

    .totals-value {
      float: right;
      text-align: right;
    }
  }

  .totals-item-total {
    font-weight: 700;
  }
}

/* Make adjustments for tablet */
@media screen and (max-width: 650px) {

  .shopping-cart {
    margin: 0;
    padding-top: 20px;
    border-top: 1px solid $white;
  }

  .column-labels {
    display: none;
  }

  .product-image {
    float: right;
    width: auto;
    img {
      margin: 0 0 10px 10px;
    }
  }

  .product-details {
    float: none;
    margin-bottom: 10px;
    width: auto;
  }

  .product-price {
    clear: both;
    width: 70px;
  }

  .product-quantity {
    width: 100px;
    input {
      margin-left: 20px;
    }
  }

  //  .product-quantity:before {
  //    content: 'x';
  //  }

  .product-removal {
    width: auto;
  }

  .product-line-price {
    float: right;
    width: 70px;
  }

}


/* Make more adjustments for phone */
@media screen and (max-width: 350px) {

  .product-removal {
    float: right;
  }

  .product-line-price {
    clear: left;
    float: right;
    margin-top: 10px;
    width: auto;
  }

  .product .product-line-price:before {
    content: 'Összeg: Ft';
  }


  .totals {
    .totals-item {
      label {
        width: 60%;
      }

      .totals-value {
        width: 40%;
      }
    }
  }
}



