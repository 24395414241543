//ng-select .ng-select-container span.ng-arrow-wrapper span.ng-arrow {
//  &::before {
//    border-style: solid;
//    border-width: .15em .15em 0 0;
//    content: '';
//    display: inline-block;
//    height: .45em;
//    left: .15em;
//    position: relative;
//    top: 0;
//    transform: rotate(135deg);
//    vertical-align: top;
//    width: .45em;
//  }
//
//
//}

.ng-select.ng-select-opened {
  >.ng-select-container {
    background: $gray-400;
    border-radius: 0;
    // padding: .3em;
  //  border-color: $gray-700 $gray-700 $gray-500;
   // border: 1px dashed $gray-600;
    //z-index: 100;
    &:hover {
      box-shadow: none;
    }
    .ng-arrow {
      border-color: transparent transparent $gray-500;
      border-width: 0 5px 5px;
      top: -2px;
      &:hover {
        border-color: transparent transparent $gray-500;
      }
    }
  }
}
.ng-select.ng-select-opened.ng-select-bottom {
  >.ng-select-container {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
.ng-select.ng-select-opened.ng-select-top {
  >.ng-select-container {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
.ng-select.ng-select-focused {
  &:not(.ng-select-opened) {
    >.ng-select-container {
      border-color: $blue;
      box-shadow: inset 0 1px 1px rgba(0,0,0,0.075),0 0 0 3px rgba(0,126,255,0.1);
    }
  }
}
.ng-select.ng-select-disabled {
  >.ng-select-container {
    background-color: $gray-100;
  }
}
.ng-select {
  .ng-has-value {
    .ng-placeholder {
      display: none;
    }
  }

  .ng-select-container {
    align-items: center;
    background-color: $primary;
    color: $white;
   // border-radius: $border-radius-sm;
    // border: 1px solid red;
    min-height: 28px;
    width: 100%;
    &:hover {
      box-shadow: 0 1px 0 rgba(0,0,0,0.06);
    }
    .ng-value-container {
      align-items: self-start;
     // height: 3em;
      overflow-x: hidden;
      overflow-y: scroll;
      padding-left: 5px;

      .ng-placeholder {
        color: $gray-800;
      }
    }
  }
  .ng-clear-wrapper {
    color: $gray-800;
    margin-top: 1px;
    padding-left: 15px;
    margin-right: 5px;
    &:hover {
      .ng-clear {
        color: $red;
      }
    }
  }
  .ng-spinner-zone {
    padding: 5px 5px 0 0;
  }
  .ng-arrow-wrapper {
    padding-right: 0;
    width: 25px;
    &:hover {
      //.ng-arrow {
      //  border-top-color: $gray-500;
      //}
    }
    .ng-arrow {
      padding-right: .5em;

      &::before {
      border-style: solid;
      border-width: 1px 1px 0 0;
      content: '';
      display: inline-block;
      height: .52em;
      left: .15em;
      position: relative;
      top: .3em;
      transform: rotate(135deg);
      vertical-align: top;
      width: .52em;
      //border-color: $gray-800 transparent transparent;
      //border-style: solid;
      //border-width: 5px 5px 2.5px;
    }
    }
  }
}

.ng-select.ng-select-single {
  .ng-select-container {
    // height: 30px;
    .ng-value-container {
      .ng-input {
        left: 0;
        padding-left: 0;
        padding-right: 50px;
        top: 0;
      }
    }
  }
}
.ng-select.ng-select-multiple.ng-select-disabled {
  >.ng-select-container {
    .ng-value-container {
      .ng-value {
        background-color: #f9f9f9;
       // background-color: $info;
        border: 1px solid #e6e6e6;
        .ng-value-label {
          padding: 0 5px;
        }
      }
    }
  }
}
.ng-select.ng-select-multiple {
  .ng-select-container {
    background-color: $white;
    color: $gray-900;
    border-radius: 0;
    // border: 1px solid $gray-500;
   // min-height: 30px;

    scrollbar-color: $primary;
    scrollbar-width: thin;

    //::-webkit-scrollbar {
    //  width: 12px;
    //  height: 12px;
    //}
    ::-webkit-scrollbar-track {
      background: $gray-200;
    }
    ::-webkit-scrollbar-thumb {
      background: $primary;
    }



    .ng-value-container {
      padding-left: 7px;
      padding-top: 5px;
      .ng-value {
        background-color: #ebf5ff;
        border-radius: 0;
        font-size: .9em;
        margin-bottom: 5px;
        margin-right: 5px;
        .ng-value-label {
          display: inline-block;
          padding: 1px 5px;
        }
        .ng-value-icon {
          display: inline-block;
          padding: 1px 5px;
          &:hover {
            background-color: #d1e8ff;
          }
        }
        .ng-value-icon.left {
          border-right: 1px solid #b8dbff;
        }
        .ng-value-icon.right {
          border-left: 1px solid #b8dbff;
        }
      }
      .ng-value.ng-value-disabled {
        background-color: $gray-100;
        .ng-value-label {
          padding-left: 5px;
        }
      }
      .ng-input {
        padding: 0 0 3px 3px;
      }
      .ng-placeholder {
        top: 5px;
        padding-bottom: 5px;
        padding-left: 3px;
      }
    }
  }
}
.ng-dropdown-panel {
  background-color: $white;
  border: 0;
  box-shadow: 0 0 12px rgba(0,0,0,0.3);
  left: 0;
  .ng-dropdown-header {
    border-bottom: 1px solid $gray-500;
    padding: 5px 7px;
  }
  .ng-dropdown-footer {
    border-top: 1px solid $gray-500;
    padding: 5px 7px;
  }
  .ng-dropdown-panel-items {
  // margin-right: .3em;
   // margin-left: -1em;
    border: 1px solid $gray-400;

    //scrollbar-color: $purple  !important;
    //scrollbar-width: thin  !important;
    //
    //::-webkit-scrollbar {
    //  width: 12px;
    //  height: 12px;
    //}
    //::-webkit-scrollbar-track {
    //  background: $purple !important;
    //}
    //::-webkit-scrollbar-thumb {
    //  background: darken($purple, 20%)  !important;
    //}


    .ng-optgroup {
      user-select: none;
      padding: 8px 10px;
      font-weight: 500;
      color: rgba(0,0,0,0.54);
      cursor: pointer;
    }
    .ng-optgroup.ng-option-disabled {
      cursor: default;
    }
    .ng-optgroup.ng-option-marked {
      background-color: #f5faff;
    }
    .ng-optgroup.ng-option-selected {
      background-color: #ebf5ff;
      font-weight: 600;
    }
    .ng-optgroup.ng-option-selected.ng-option-marked {
      background-color: #ebf5ff;
      font-weight: 600;
    }
    .ng-option {
      background-color: $primary;
      font-family: $headings-font-family;
      color: $white;
      padding: 4px 10px;
      z-index: 100;
      .ng-tag-label {
        font-size: 80%;
        font-weight: 400;
        padding-right: 5px;
      }
    }
    .ng-option.ng-option-selected {
      color: $white;
      background-color: $gray-800;
      .ng-option-label {
        font-weight: 600;
      }
    }
    .ng-option.ng-option-selected.ng-option-marked {
      color: $white;
      background-color: $gray-800;
      .ng-option-label {
        font-weight: 600;
      }
    }
    .ng-option.ng-option-marked {
      background-color: $gray-800;
      color: $white;
    }
    .ng-option.ng-option-disabled {
      color: $gray-500;
    }
    .ng-option.ng-option-child {
      padding-left: 22px;
    }
  }
}
.ng-dropdown-panel.ng-select-bottom {
  top: 100%;
  //border-bottom-right-radius: 4px;
  //border-bottom-left-radius: 4px;
  border-top-color: #e6e6e6;
  margin-top: 3px;
  //.ng-dropdown-panel-items {
  //  .ng-option {
  //    &:last-child {
  //      border-bottom-right-radius: 4px;
  //      border-bottom-left-radius: 4px;
  //    }
  //  }
  //}
}
.ng-dropdown-panel.ng-select-top {
  border-bottom-color: #e6e6e6;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  bottom: 100%;
  margin-bottom: -1px;
  .ng-dropdown-panel-items {
    .ng-option {
      &:first-child {
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
      }
    }
  }
}



