.breadcrumb {
  background-color: transparent;
  list-style: none;
  // @include box-shadow($box-shadow-sm);
  margin-bottom: 0;
  overflow: hidden;
  padding: .5em 0 0 0;

  //ol {
  //  list-style-type: none;
  //  margin: 0;
  //  padding: 0;
  //}


  li {
    color: $white;
    float: left;
    font-family: $headings-font-family;
    font-size: .75rem;
    font-weight: 700;
    text-transform: uppercase;

    @include media-breakpoint-up(md) {
      font-size: .85rem;
    }

    &:last-child span {
      background: lighten($gray-600, 20%) !important;
      padding-right: 12px;

      &:before{
        border-color: lighten($gray-600, 20%);
        border-left-color: transparent;
        @include transition-all;
      }
      &:after{
        border-left-color: lighten($gray-600, 20%);
        @include transition-all;
      }

    }

    &:first-child {
      a {
        padding-left: 10px;
        @include border-radius($border-radius 0 0 $border-radius);
        &:before{
          border: 0;
        }
      }
    }

    a, span {

      //@include box-shadow($box-shadow);

      color: $white;
      display: inline-block;
      height: 32px;
      line-height: 32px;
      padding: 0 4px 0 0;
      position: relative;

      &:before{
        left: -20px;
        border-left-color: transparent;
      }
      &:after{
        border-color: transparent;
        border-left-color: $info;
        left: 100%;
      }

      &:before,
      &:after{
        @include transition-all;
        border: 0 solid $info;
        border-width: 16px 10px;
        content: "";
        height: 0;
        position: absolute;
        top: 0;
        width: 0;
      }
    }


    a {
      background-color: $primary;
      margin-right: 21px;
      text-decoration: none;

      @include media-breakpoint-up(lg) {
        margin-right: 21px;
      }



      &:hover{
        background-color: lighten($primary, 10%);
        @include transition-all;


        &:before{
          border-color: lighten($primary, 10%);
          border-left-color:transparent;
          @include transition-all;
        }
        &:after{
          border-left-color:lighten($primary, 10%);
          @include transition-all;
        }
      }


      &:before,
      &:after{
        @include transition-all;
        content: "";
        position:absolute;
        top: 0;
        border: 0 solid $primary;
        border-width: 16px 10px;
        width: 0;
        height: 0;
      }
      &:before{
        left:-20px;
        border-left-color:transparent;
      }
      &:after{
        left:100%;
        border-color:transparent;
        border-left-color:$primary;
      }


      &:hover{
        background-color: darken($primary, 5%);
        @include transition-all;


        &:before{
          border-color: darken($primary, 5%);
          border-left-color:transparent;
          @include transition-all;
        }
        &:after{
          border-left-color:darken($primary, 5%);
          @include transition-all;
        }
      }


    }
    //&:nth-child(even){
    //  a{
    //    background-color: $blue-darken;
    //
    //    &:before{
    //      border-color:$blue-darken;
    //      border-left-color:transparent;
    //    }
    //    &:after{
    //      border-left-color:$blue-darken;
    //    }
    //  }
    //}




    span {

      @include transition(all 0.25s ease-in-out);
      padding-right: 26px;
      background-color: $gray-600;
      &:before{
        border-color: $gray-600;
        border-left-color:transparent;
        @include transition-all;
      }
      &:after{
        border-left-color: $gray-600;
        @include transition-all;
      }
      @include border-radius(0 $border-radius $border-radius  0);
      &:after{
        border: 0;
      }
    }


  }

}



