@use '~@angular/material' as mat;

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// define a real custom palette (using http://mcg.mbitson.com)
$conte-brand: (
  50: #ffffff,
  100: lighten($primary, 20%),
  200: lighten($primary, 15%),
  300: lighten($primary, 10%),
  400: lighten($primary, 5%),
  500: $primary,
  600: darken($primary, 5%),
  700: darken($primary, 10%),
  800: darken($primary, 15%),
  900: darken($primary, 20%),
  A100: #ffffff,
  A200: lighten($primary, 18%),
  A400: darken($primary, 4%),
  A700: darken($primary, 18%),
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: rgba(black, 0.87),
    A700: white,
  )
);

$conte-orange: (
  50: #ffffff,
  100: #fff7f4,
  200: #fecdbd,
  300: #fc9977,
  400: #fc8259,
  500: #fb6c3b,
  600: #fa551d,
  700: #f44205,
  800: #d63a04,
  900: #b83204,
  A100: #ffffff,
  A200: #fff7f4,
  A400: #fc8259,
  A700: #f44205,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: rgba(black, 0.87),
    A700: white,
  )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
//$conte-primary: mat.define-palette(mat.$blue-palette);
//$conte-accent:  mat.define-palette(mat.$light-blue-palette, 600, 300, 600);

$conte-primary: mat.define-palette($conte-brand);
$conte-accent:  mat.define-palette($conte-orange);

// The warn palette is optional (defaults to red).
$conte-warn: mat.define-palette(mat.$red-palette);



// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$conte-theme: mat.define-light-theme((
  color: (
    primary: $conte-primary,
    accent: $conte-accent,
    warn: $conte-warn,
  )
));



// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($conte-theme);




// CUSTOM EDITS /////////////////////////////////////////////////////

.mat-tab-group{
  font-family: $font-family-sans-serif, sans-serif;
  padding: 0;
  margin: 0;
  font-size: 1rem;
  line-height: 1.4;
}

//.mat-ripple {
//  padding: 0;
//}

.mat-tab-label,.mat-tab-link{
  font-family: $headings-font-family, sans-serif;
  font-size: .75rem;

  .fa {
    font-size: .85rem;
  }

  @include media-breakpoint-up(sm) {
    font-size: 1rem;
  }

  //@include media-breakpoint-up(md) {
  //  font-size: 1rem;
  //}
  //
  //@include media-breakpoint-up(lg) {
  //  font-size: 1rem;
  //}
  //
  //@include media-breakpoint-up(xl) {
  //  font-size: 1rem;
  //}

  font-weight: 700;
  text-transform: uppercase;
}

//.mat-tab-body-content {
//  width: 90%;
//}

.mat-drawer {
  overflow-y: hidden;
}

.mat-tab-body {
  //@include media-breakpoint-up(lg) {
  //  min-height: 210px;
  //}
}

.mat-tab-body-content {
  overflow-x: hidden!important;
  padding-top: $grid-gutter-width;
}


.mat-button-base {
  border-radius: 10em !important;
  text-transform: uppercase !important;
  font-family: $font-family-sans-serif;
  font-size: 1rem;
  font-weight: 600;

  .fa {
    font-size: .75rem;
    padding-bottom: .1em;

  }
 // margin-right: $spacer !important;
}

.mat-tab-label-content {
  // color: $black !important;
  font-family: $headings-font-family !important;
  font-weight: 500 !important;
  color: $primary !important;
}



