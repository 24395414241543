.star-text {
  color: $white;
  font-family: $headings-font-family;
  font-size: 5rem;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}

.starburst {
  height: $badge-size;
  position: absolute;
  right: 10px;
  top: 14px;
  width: $badge-size;

}

.burst-one {
  background: $primary;
  border-radius: 6px;
  height: $badge-size;
  left: 0;
  position: absolute;
  top: 0;
  transform: rotate(20deg);
  width: $badge-size;
}
.burst-one:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: $badge-size;
  width: $badge-size;
  background: $primary;
  transform: rotate(135deg);
  border-radius: 6px;
}

.burst-two {
  background: $primary;
  border-radius: 6px;
  height: $badge-size;
  left: 0;
  position: absolute;
  top: 0;
  transform: rotate(44deg);
  width: $badge-size;
}
.burst-two:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: $badge-size;
  width: $badge-size;
  background: $primary;
  transform: rotate(135deg);
  border-radius: 6px;
}

.textcircle {
  width: $badge-size;
 // z-index: 5;
  position: absolute;
  //top: 0;
  //left: 0;
}
