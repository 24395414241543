// BOOTSTRAP VARIABLES ////////////////////////////////////////////////////////////////////

// Color palette

// * CONTE COLORS */
  $white:    #fff;
  $gray-100: #E1E1E1;
  $gray-200: #C4C4C4;
  $gray-300: #8D8D8D;
  $gray-400: #727272;
  $gray-500: #595959;
  $gray-600: #51606B;
  $gray-700: #414141;
  $gray-800: #2A2A2A;
  $gray-900: #151515;
  $black:    #000;

  $blue:     #8DD8F2;
  $indigo:   #D98DF2;
  $purple:   #9C8DF2;
  $pink:     #F3DCD4;
  $red:      #F2A68D;
  $orange:   #F2E38D;
  $yellow:   #9D9240;
  $green:    #C4F28D;
  $teal:     #52D2A4;
  $cyan:     #8DF2ED;

  $primary:       #D2462D; // logo red
  $secondary:     $red;
  $success:       $blue;
  $info:          $yellow;
  $warning:       $orange;
  $danger:        $primary;

// * OPTIONS */
  $enable-caret:                  false;
  $enable-rounded:                true;
  $enable-shadows:                true;
  $enable-gradients:              true;
  $enable-responsive-font-sizes:  true;
  $enable-validation-icons:       false;

// * Typography */
  $font-family-sans-serif:        Exo;
  $headings-font-family:          Barlow;
  $font-size-base:                1rem;
  $paragraph-margin-bottom:       1rem;
  $line-height-base:              1.35;

  $font-weight-light:             200;  // 100, 200, 300, 400
  $font-weight-normal:            300;  // 200, 300, 400, 500
  $font-weight-bold:              500;  // 300, 400, 500, 600, 700, 800, 900

  $lead-font-size:                $font-size-base * 1.15;
  $lead-font-weight:              $font-weight-bold;

// * HEADINGS */
  $h1-font-size:                  $font-size-base * 3;
  $h2-font-size:                  $font-size-base * 2;
  $headings-font-weight:          100;
  $headings-line-height:          1.2;
  $headings-color:                $black;
  $headings-margin-bottom:        spacer / 2;

// Extended xxl
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1600px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1440px, // xxl: 1320px
  xxxl: 1640px
);

  $grid-gutter-width:             1.75rem;
  $spacer:                        .25rem;
  $border-radius:                 1.5rem;
  $border-radius-sm:              .75rem;
  $grid-columns:                  24;
  $table-accent-bg:               lighten($success, 50%);


// * Body */
  $body-bg:                       $white;
  $body-color:                    $gray-900;

// * Links */
  $link-color:                    $primary;
  $link-decoration:               underline;
  $link-shade-percentage:         20%;
  $link-hover-color:              shift-color($link-color, $link-shade-percentage);
  $link-hover-decoration:         underscore;

// * Field selection */
  $selection-color:              lighten($orange, 5%);


// * Inputs */
  $input-focus-bg:                $white;
  $form-select-bg:                $white;
  $form-check-input-width:        1.35em;
  $text-muted:                    $gray-300;
  $input-disabled-bg:             $gray-100;
  $bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));


$tooltip-font-size:                 .85rem;
$tooltip-max-width:                 200px;
$tooltip-color:                     $white;
$tooltip-bg:                        $black;
$tooltip-border-radius:             $border-radius;
$tooltip-opacity:                   1;
$tooltip-padding-y:                 $spacer * 2;
$tooltip-padding-x:                 $spacer * 2;
$tooltip-margin:                    0;

$tooltip-arrow-width:               1rem;
$tooltip-arrow-height:              .8rem;
$tooltip-arrow-color:               $tooltip-bg;

// OUR OWN PARAMETERS
$badge-size: 3.5rem;
