// CUSTOM BUTTONS ///////////////////////////////////////////////////

.btn {
  text-transform: uppercase;
  font-family: $headings-font-family;
  font-weight: 400;
}

.btn-quantity {

  //width: 92px;
  height: 30px;
  font-family: $headings-font-family;

  &:disabled {
    color: $white !important;
    cursor: default;

    .fa {
      color: $white;
      cursor: default !important;
      &:hover {
        color: $white !important;
      }
    }
  }
}

.btn-piece {

  background-color: lighten($primary, 20%);
 //  padding-left: .2em;
  //padding-right: .2em;
  //line-height: 1.2em;
  border-color: lighten($primary, 20%);
  font-size: 1.085rem;
//  padding-top: .2em;

  //&:hover {
  //  cursor: default !important;
  //  border-color: lighten($blue, 20%);
  //}

  //span {
  //  width: auto;
  //  height: 100%;
  //  margin-left: 1px;
  //  margin-right: 1px;
  //
  //}

}

.btn-sub {
    border-bottom-left-radius: 50%;
    border-top-left-radius: 50%;
}

.btn-add {
    border-bottom-right-radius: 50%;
    border-top-right-radius: 50%;
}

.btn-sub, .btn-add {
 // width: 40px;
 // padding: 0;
  // height: 2em;
  //padding-top: .3em;
  //padding-bottom: .3em;
  background-color: lighten($primary, 10%);
  color: $white;
  font-size: .85rem;
 // line-height: 0;
  @include transition-all;
  //width: .5em;

  //&:hover {
  //  background-color: lighten($blue, 15%);
  //  cursor: pointer;
  //}
}


.btn-cart {
  color: $danger;
}

.btn-detail {
  color: lighten($blue, 15%);

  .fa {
    color: $gray-800;
  }
}

.btn-rounded {
  @include border-radius(10em);
  font-family: $headings-font-family;
  font-size: 1rem;
  text-transform: uppercase;

  &:disabled {
    &:hover{
      cursor: not-allowed;
    }
  }

}

.add-card-txt {
  margin-left: .2em;
  //display: none;
  //@media only screen and (min-width: 1600px) {
  //  display: inline;
  //}
}
