$enableNgDeep: true !default;

$handleActiveColor: $gray-300;
$handleHoverColor: $white;
$labelTextColor: $gray-900;
$handleBgColor: $primary;
$handleInnerColor: $white;
$handleDisabledColor: $gray-100;
$limitLabelTextColor: $gray-200;
$barFillColor: $handleBgColor;
$barDisabledFillColor: $gray-600;
$barNormalColor: $gray-200;
$barLeftOutSelectionColor: $warning;
$barRightOutSelectionColor: $success !default;

$ticksColor: $barNormalColor;
$selectedTicksColor: $barFillColor;
$ticksWidth: 10px !default;
$ticksHeight: 10px !default;
$ticksValuePosition: -34px !default;
$ticksLegendPosition: 24px !default;
$ticksValuePositionOnVertical: 24px !default;
$tickLegendMaxWidth: 50px !default;

$handleSize: 32px !default;
$handlePointerSize: 8px !default;
$bubblePaddingVertical: 1px !default;
$bubblePaddingHorizontal: 3px !default;
$labelFontSize: 16px !default;
$barDimension: 4px !default;

$withLegendMargin: 40px !default;

$sliderMargin: 15px !default;
$sliderMarginWithLabel: 35px !default;
$sliderVerticalMargin: 20px !default;

$animationDuration: 0.3s !default;


  .ngx-slider {
    &[disabled] {
      .ngx-slider-pointer {
        background-color: $handleDisabledColor !important;
      }


      .ngx-slider-selection {
        background: $barDisabledFillColor !important;
      }

      .ngx-slider-tick {

        &.ngx-slider-selected {
          background: $barDisabledFillColor !important;
        }
      }
    }


    .ngx-slider-bar {
      background: $barNormalColor !important;
    }

    .ngx-slider-bar-wrapper.ngx-slider-left-out-selection .ngx-slider-bar {
      background: $barLeftOutSelectionColor !important;
    }

    .ngx-slider-bar-wrapper.ngx-slider-right-out-selection .ngx-slider-bar {
      background: $barRightOutSelectionColor !important;
    }

    .ngx-slider-selection {
      background: $barFillColor !important;
    }

    .ngx-slider-pointer {
      background-color: $handleBgColor !important;

      &:after {
        background: $handleInnerColor !important;
      }

      &:hover:after {
        background-color: $handleHoverColor !important;
      }

      &.ngx-slider-active {

        &:after {
          background-color: $handleActiveColor !important;
        }
      }
    }

    .ngx-slider-bubble {
      color: $labelTextColor !important;

      &.ngx-slider-limit {
        color: $limitLabelTextColor !important;
      }
    }

    .ngx-slider-tick {
      background: $ticksColor !important;

      &.ngx-slider-selected {
        background: $selectedTicksColor !important;
      }
    }
  }
